// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-amp-js": () => import("./../src/templates/blog-post.amp.js" /* webpackChunkName: "component---src-templates-blog-post-amp-js" */),
  "component---src-templates-offer-js": () => import("./../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-generic-template-js": () => import("./../src/templates/genericTemplate.js" /* webpackChunkName: "component---src-templates-generic-template-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-confronta-js": () => import("./../src/pages/confronta.js" /* webpackChunkName: "component---src-pages-confronta-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pos-piu-conveniente-js": () => import("./../src/pages/pos-piu-conveniente.js" /* webpackChunkName: "component---src-pages-pos-piu-conveniente-js" */),
  "component---src-pages-step-1-js": () => import("./../src/pages/step-1.js" /* webpackChunkName: "component---src-pages-step-1-js" */),
  "component---src-pages-step-2-js": () => import("./../src/pages/step-2.js" /* webpackChunkName: "component---src-pages-step-2-js" */)
}

